.cta {
	.section-inner {
		padding-top: $cta--padding-t__mobile;
		padding-bottom: $cta--padding-b__mobile;
	}
}

.cta-slogan {
	margin-bottom: $cta-slogan--padding-v__mobile;

	p {
		color: #cad1d1
	}
}

.cta-action {
	.has-icon-right {
		> div {
			top: 0;
			right: 0;
			width: 65px;
			height: 100%;
			position: absolute;

			> span {
				top: 6px;
				right: 0.5rem;
				font-size: 15px;
				position: relative;
				color: get-color(primary, 1);
			}

			> svg {
				top: 0;
			}

			&:hover {
				cursor: pointer;

				> span {
					color: get-color(primary, 3)
				}
			}
		}

		&.loading {
			> div:hover {
				cursor: default;
				pointer-events: none;
			}
		}
	}
}

@include media(">medium") {
	.cta {
		.section-inner {
			padding-top: $cta--padding-t__desktop;
			padding-bottom: $cta--padding-b__desktop;
		}
	}

	.cta-split {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.cta-slogan {
		margin-bottom: $cta-slogan--padding-v__desktop;

		.cta-split & {
			margin-bottom: 0;
			margin-right: $cta-slogan--padding-h__desktop;
		}
	}
}

.checkmark__circle {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	stroke: #7ac142;
	fill: none;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
	right: 10px;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	display: block;
	stroke-width: 2;
	stroke: #fff;
	stroke-miterlimit: 10;
	box-shadow: inset 0px 0px 0px #7ac142;
	animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	transform-origin: 50% 50%;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}
@keyframes scale {
	0%,
	100% {
		transform: translateY(-50%);
	}
	50% {
		transform: translateY(-50%), scale3d(1.1, 1.1, 1);
	}
}
@keyframes fill {
	100% {
		box-shadow: inset 0px 0px 0px 30px #7ac142;
	}
}
