// https://codepen.io/hansmaad/pen/QGYpey

$md-chip-height: 32px;
$md-color: color-bg(button-light);

.md-chip {
    display: inline-block;
    padding: 0 12px;
    border-radius: 32px;
    font-size: 13px;
    border-width: 2px;
    border-style: solid;
    border-color: color(primary);
    color:$md-color;
    &.md-chip-hover:hover {
        opacity: 0.8;
    }
}

.md-chip-clickable {
    cursor: pointer;
}

.md-chip,
.md-chip-icon {
    height: $md-chip-height;
    line-height: 29px;
}

.md-chip-icon {
    display: block;
    float: left;
    width: $md-chip-height;
    border-radius: 50%;
    text-align: center;
    color: $md-color;
    margin: 0 0 0 -12px;
}

.md-chip-remove {
    display: inline-block;
    background: #aaa;
    border: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    padding: 0;
    margin: 0 -4px 0 4px;
    cursor: pointer;
    font: inherit;
    line-height: 20px;

    &:after {
        color: $md-color;
        content: "x";
    }
    
    &:hover {
        background: #999;
    }

    &:active {
        background: #777;
    }
}

.md-chips {
    padding: 12px 0;

    .md-chip {
        margin: 0 .5rem .5rem 0;
    }
}